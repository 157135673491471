.bg {
    width: 100%;
    height: 55vh;
    color: #fff;
    position: relative;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    display: flex;
}

.bg .gradient {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background: -moz-linear-gradient(left, rgba(255, 0, 0, 0.3) 0%, rgba(255, 240, 0, 0.3) 100%); */
    /* background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0.3) 0%, rgba(255, 240, 0, 0.3) 100%); */
    background: linear-gradient(to right, rgba(255, 0, 0, 0.1) 0%, rgba(255, 240, 0, 0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dff0000', endColorstr='#4dfff000', GradientType=1);
}

.bg .after {
    content: "";
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    position: absolute;
    display: inline-block;
}

.btn {
    font-size: 32px !important;
    color: white !important;
    border: 1px solid white !important;
    border-radius: 32px !important;
    padding: 8px 48px !important;
    text-decoration: none !important;
    margin-top: 60px !important;

}

.btn:hover {
    border: 1px solid #FEC569 !important;
    color: #FEC569 !important;
}
