.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}
.image {
  height: 60px;
  width: 100%;
  object-fit: cover;
}
