.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 16px;
    position: relative;
}

.image {
    height: 60px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
