.card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
}
.image {
    height: 60px;
    width: 100%;
    object-fit: cover;
}
